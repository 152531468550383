import React from "react";
import Header from "../components/header/Header";
import Blog from "../components/blog/Blog";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import "../assets/scss/main.scss";
import ContactAndInfo from "../components/ContactAndInfo";

const HomeOne = () => {
  return (
    <>
      <Header />

      <section id="blog" className="section gray-bg">
        <div className="container" style={{'paddingTop':'100px'}}>
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Tous mes articles</h3>
              <p style={{'color':'hsl(50deg 60% 21%)'}}>
                Retrouvez ici toutes mes explications, mes sujets de réflexion, et mes conseils pratiques.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Blog count={10000} />
        </div>
      </section>
      
    
      {/* Contact */}
      <section id="contact" className="section after-left-section">
      <ContactAndInfo />
      </section>
     
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeOne;
